<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>

    <div>
      <page-header
        title="프리미엄 콘텐츠 관리"
        description="판매 중인 프리미엄 콘텐츠를 관리하고 구매한 멤버와 데이터 분석까지 확인해보세요."
        guide-link="https://bigc-im.notion.site/03a9db5551de463780d3a6917165eb48"
      ></page-header>

      <div v-if="!state.pageLoading" class="container-body">
        <div class="tab-create-btn">
          <div class="tab-section">
            <button
              class="selling-tab sub-title-s1"
              :class="{ active: !state.isTemporary }"
              @click="actions.filterClubs({ isTemporary: 0 })"
            >
              {{ `판매 목록 ${state.clubsMeta.openClubCount}` }}
            </button>

            <text-divider
              color="#ECF1F4"
              height="20px"
              margin="0 20px"
            ></text-divider>

            <button
              class="selling-tab sub-title-s1"
              :class="{ active: state.isTemporary }"
              @click="actions.filterClubs({ isTemporary: 1 })"
            >
              {{ `임시 저장 ${state.clubsMeta.saveClubCount}` }}
            </button>
          </div>
          <div>
            <button-basic
              class="create-btn"
              text="클럽 생성"
              icon-position="front"
              padding="8px 16px"
              :disabled="state.isCreating"
              @click="actions.openEntClubCreateModal()"
            >
              <template #icon>
                <plus-icon fill-color="#ffffff"></plus-icon>
              </template>
            </button-basic>
          </div>
        </div>

        <div class="search-section">
          <h4>
            {{ `콘텐츠 ${state.clubsMeta.pagination.total}개` }}
          </h4>
          <div class="tools">
            <button-dropdown>
              <template #button>
                <button class="recent-updated-club-toggle">
                  <span class="sub-text-s2">
                    {{ state.postOrderLabel }}
                  </span>
                  <dash-board-arrow-icon></dash-board-arrow-icon>
                </button>
              </template>
              <template #dropdownList>
                <ul class="recent-updated-club-toggle-list">
                  <li :class="{ active: state.filters.order === 'desc' }">
                    <button @click="actions.filterClubs({ order: 'desc' })">
                      최신순
                    </button>
                  </li>
                  <li :class="{ active: state.filters.order !== 'desc' }">
                    <button @click="actions.filterClubs({ order: 'asc' })">
                      오래된 순
                    </button>
                  </li>
                </ul>
              </template>
            </button-dropdown>
            <input-search
              v-model="state.filters.q"
              placeholder="검색어를 입력해주세요."
              shape="round"
              @search="actions.filterClubs({ q: state.filters.q })"
            ></input-search>
          </div>
        </div>

        <!--        todo 모바일에서도 가능하도록 수정 필요 pc버전만 적용됨-->
        <div
          class="contents-header sub-text-s3 text-gray-second"
          :class="{ temporary: state.isTemporary }"
        >
          <span class="col1">
            <button-dropdown class="drop-down theme-filter" distance="8">
              <template #button>
                <div>
                  <span>콘텐츠 종류</span>
                  <span class="icon">
                    <filter-icon fill-color="#818287"></filter-icon>
                  </span>
                </div>
              </template>
              <template #dropdownList>
                <ul class="theme-filter-list">
                  <li
                    v-for="item in themeFilterList"
                    :key="`filter-theme-${item.id}`"
                    :class="{
                      active: state.clubsMeta.filters.type === item.value,
                    }"
                  >
                    <button
                      type="button"
                      class="sub-text-s3"
                      @click="actions.filterClubs({ type: item.value })"
                    >
                      {{ item.label }}
                    </button>
                  </li>
                </ul>
              </template>
            </button-dropdown>
          </span>
          <span class="col2">기본정보</span>
          <span class="col3">매출</span>
          <span class="col4">
            <button-dropdown
              distance="8"
              :auto-hide="true"
              :disabled="state.isTemporary"
              placement="bottom-start"
            >
              <template #button>
                <div class="status-btn">
                  <span class="txt">상태</span>
                  <filter-icon
                    :fill-color="state.isTemporary ? 'transparent' : '#818287'"
                  ></filter-icon>
                </div>
              </template>
              <template #dropdownList>
                <ul class="status-list">
                  <li
                    v-for="(status, key) in clubStatuses"
                    :key="`club-status-${key}`"
                  >
                    <label>
                      <span class="sub-text-s2">{{ status }}</span>
                      <input-checkbox
                        class="check-box"
                        :default-value="state[`isClubStatus${key}`]"
                        @updateData="
                          (checked) =>
                            actions.filterClubs({
                              status: parseInt(key),
                              checked: checked,
                            })
                        "
                      ></input-checkbox>
                    </label>
                  </li>
                </ul>
              </template>
            </button-dropdown>
          </span>
          <span class="col5">마지막 수정 날짜</span>
          <span class="col6">기능</span>
        </div>

        <!--	empty UI  -->
        <empty-list
          v-if="state.clubs.length === 0"
          :icon-image="
            !state.clubsMeta.filters.q
              ? `/assets/images/club/document.png`
              : null
          "
          :text="
            !!state.clubsMeta.filters.q
              ? `'검색어'로 검색된 콘텐츠가 없습니다.`
              : `오픈된 클럽이 없습니다.<br/>클럽을 만들어보세요!`
          "
          btn-text="클럽 생성하기"
          :is-button="!state.clubsMeta.filters.q"
          @btnAction="actions.openEntClubCreateModal()"
        >
          <template #buttonIcon>
            <arrow-icon class="arrow-icon" stroke-size="normal"></arrow-icon>
          </template>
        </empty-list>

        <ul class="club-list">
          <li v-for="club in state.clubs" :key="`club-item-${club.resourceId}`">
            <club-item
              :club="club"
              @completeCopy="actions.filterClubs({ isTemporary: 1 })"
            ></club-item>
          </li>
        </ul>

        <div
          v-if="state.clubsMeta.pagination.lastPage > 1"
          class="pagination-wrapper"
        >
          <pagination
            :pagination="state.clubsMeta.pagination"
            @getPageData="(page) => actions.filterClubs({ page: page })"
          ></pagination>
        </div>
      </div>
    </div>
    <teleport :disabled="true">
      <ent-club-create-modal
        v-if="state.showEntClubCreateModal"
        :premium-contents-themes="state.premiumContentsThemes"
        @hideModal="actions.closeEntClubCreateModal()"
        @saveComplete="(value) => actions.createEntClub(value)"
      ></ent-club-create-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, onBeforeMount, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import PageHeader from "../../../components/console/headers/PageHeader";
import Pagination from "../../../components/console/pagination/Pagination";
import ArrowIcon from "../../../components/console/icons/ArrowIcon";
import PageLoading from "../../../components/console/loadings/PageLoading";
import ClubItem from "./ClubItem/ClubItem";
import ButtonDropdown from "../../../components/console/buttons/ButtonDropdown";
import InputCheckbox from "../../../components/console/inputs/InputCheckbox";
import FilterIcon from "../../../components/console/icons/FilterIcon";
import EmptyList from "../../../components/console/empties/EmptyList";
import ApiService from "@/api";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";
import EntClubCreateModal from "./EntClubCreateModal/EntClubCreateModal";
import swal from "@/helper/swal";
import TextDivider from "@/components/console/dividers/TextDivider.vue";
import InputSearch from "@/components/console/inputs/InputSearch.vue";
import { useRoute, useRouter } from "vue-router";
import DashBoardArrowIcon from "@/components/console/icons/DashBoardArrowIcon.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";

export default {
  name: "Clubs",
  components: {
    DashBoardArrowIcon,
    InputSearch,
    TextDivider,
    EmptyList,
    FilterIcon,
    PlusIcon,
    InputCheckbox,
    ButtonDropdown,
    ClubItem,
    PageLoading,
    ArrowIcon,
    Pagination,
    PageHeader,
    ButtonBasic,
    EntClubCreateModal,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const preValue = new Map();

    let count = 0;

    const state = reactive({
      filters: {
        q: "",
        status: 0,
        perPage: 15,
        isTemporary: 0,
        type: "",
        order: "desc",
        // 고민 필요한 값일까.
        page: 1,
      },
      order: "desc", // default:desc(최신순), asc (오래된 순)
      clubs: computed(() => {
        return store.getters["newClubs/newClubs"];
      }),
      clubsMeta: computed(() => {
        return store.getters["newClubs/newClubsMeta"];
      }),
      pageLoading: false,
      // reservation 1
      isClubStatus1: computed(() => {
        // 1 3 5 7 9 11 13 15
        let status = Number(state.filters.status);
        return status & 1 ? true : false;
      }),
      // open 2
      isClubStatus2: computed(() => {
        // 2 3 6 7 10 11 14 15
        let status = Number(state.filters.status);
        return status & 2 ? true : false;
      }),
      // finished 4
      isClubStatus4: computed(() => {
        // 4 5 6 7 12 13 14 15
        let status = Number(state.filters.status);
        return status & 4 ? true : false;
      }),
      // hidden 8
      isClubStatus8: computed(() => {
        // 8 9 10 11 12 13 14 15
        let status = Number(state.filters.status);
        return status & 8 ? true : false;
      }),
      publicCheck: false,
      reservePublicCheck: false,
      notPublicCheck: false,
      isTemporary: computed(() => {
        let isTemporary = state.clubsMeta.filters.isTemporary;
        console.log("computed", state.clubsMeta.filters.isTemporary);
        return Number(isTemporary) === 1 ? true : false;
      }),
      isCreating: false,
      showEntClubCreateModal: false,
      premiumContentsThemes: [],
      postOrderLabel: computed(() => {
        return state.filters.order === "desc" ? "최신순" : "오래된 순";
      }),
    });

    const themeFilterList = [
      {
        id: 1,
        label: "전체",
        value: "",
      },
      {
        id: 2,
        label: "LIVE",
        value: 11,
      },
      {
        id: 3,
        label: "VOD",
        value: 21,
      },
      {
        id: 4,
        label: "E-Book",
        value: 22,
      },
      {
        id: 5,
        label: "Podcast",
        value: 24,
      },
    ];

    const clubStatuses = {
      1: "판매 예정",
      2: "판매중",
      4: "판매종료",
      8: "숨김",
    };

    onBeforeMount(async () => {
      ApiService.getClubAllThemes().then((res) => {
        if (res.data.success) {
          let premiumContents = res.data.data.premiumContents;
          state.premiumContentsThemes = premiumContents;
        }
      });

      // todo 유저가 /console/clubs로 치고 들어왔다는 가정 고려.
      let queryData = { ...route.query };
      state.filters = { ...state.filters, ...queryData };
      await getClubs();
    });

    onMounted(() => {
      preValue
        .set("q", state.filters.q)
        .set("isTemporary", state.filters.status);
    });

    watch(
      () => route.query,
      async (query) => {
        await getClubs();
      }
    );

    const moveRouteQueryParameter = () => {
      router.push({ path: "/console/clubs", query: state.filters });
    };

    const getClubs = () => {
      state.pageLoading = true;
      const queryParams = { ...route.query };
      state.filters.isTemporary = Number(state.filters.isTemporary);
      store.dispatch("newClubs/getClubs", queryParams).then(() => {
        state.filters = { ...state.filters, ...state.clubsMeta.filters };
        state.pageLoading = false;
      });
    };

    const resetPageNum = () => {
      state.filters.page = 1;
    };

    const actions = {
      filterClubs: (data) => {
        const isTemporaryData = Object.keys(data).includes("isTemporary");
        const isStatusData = Object.keys(data).includes("status");
        const isTypeData = Object.keys(data).includes("type");
        const isQdata = Object.keys(data).includes("q");
        const isOrder = Object.keys(data).includes("order");
        const isPage = Object.keys(data).includes("page");

        console.log("isTemporaryData", isTemporaryData);
        console.log("isStatusData", isStatusData);
        console.log("isTypeData", isTypeData);

        if (isPage) {
          state.filters.page = data.page;
        }

        if (isStatusData) {
          if (data.checked) {
            state.filters.status = Number(state.filters.status) + data.status;
          } else {
            state.filters.status = Number(state.filters.status) - data.status;
          }
          resetPageNum();
        }

        if (isTypeData) {
          state.filters.type = data.type;
          resetPageNum();
        }

        if (isOrder) {
          state.filters.order = data.order;
        }

        if (isTemporaryData) {
          state.filters.isTemporary = data.isTemporary;
          resetPageNum();
        }

        // observer data
        if (isQdata) {
          if (data.q !== preValue.get("q")) {
            state.filters.q = data.q;
            preValue.set("q", data.q);
            resetPageNum();
          }
        }

        moveRouteQueryParameter();
      },
      createEntClub: (clubForm) => {
        if (!state.isCreating) {
          store
            .dispatch("newClubs/postClub", {
              data: clubForm,
            })
            .then(() => {
              state.showEntClubCreateModal = false;
              swal.successToast("생성되었습니다.");
              //note 클럽 생성 후 클럽의 isTemporary 값에 따라 보여지는 화면 조정
              state.filters.isTemporary = clubForm.isTemporary;
              getClubs();
            });
        }
      },
      openEntClubCreateModal: () => {
        state.showEntClubCreateModal = true;
      },
      closeEntClubCreateModal: () => {
        state.showEntClubCreateModal = false;
      },
    };

    return { state, actions, getClubs, themeFilterList, clubStatuses };
  },
};
</script>

<style scoped src="./style.css"></style>

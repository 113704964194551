<template>
  <div class="club-plan-item">
    <span class="col1 sub-text-s2">
      {{ plan.title }}
    </span>
    <span class="col2 sub-text-s2">{{ state.salesPrice }}</span>
    <div class="col3">
      <button-dropdown distance="8">
        <template #button>
          <span class="status-btn sub-text-s2">
            {{ statusLabel }}
          </span>
          <dash-board-arrow-icon></dash-board-arrow-icon>
        </template>
        <template #dropdownList>
          <ul class="status-btn-list">
            <li
              v-for="(status, key) in $const.planStatus"
              :key="`plan-status-${key}`"
            >
              <button
                v-close-popper
                class="sub-text-s2"
                :class="{
                  'text-gray-third': plan.status === status.value,
                }"
                :disabled="plan.status === status.value"
                @click="actions.updatePlanStatus(status.value)"
              >
                {{ status.label }}
              </button>
            </li>
          </ul>
        </template>
      </button-dropdown>
    </div>
    <div class="col4">
      <div v-if="state.showPriceInfo" class="origin-price">
        <span class="sub-text-s3 text-gray-second">
          {{ state.listPrice }}
        </span>
        <span class="sub-text-s3 text-red-50">
          {{ state.discount }}
        </span>
      </div>
      <span class="sub-text-s2">
        {{ state.price }}
      </span>
    </div>
    <div class="col5">
      <span
        class="sub-text-s3"
        :class="state.soldOut ? 'text-red-50' : 'text-blue-50'"
      >
        {{ plan.paymentOrderCount }}명 구매
      </span>
      <br />
      <span class="sub-text-s3">
        {{ state.limitCount }}
      </span>
    </div>
    <div class="col6 sub-text-s3">
      {{ state.remainTickets }}
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import moment from "moment-timezone";
import Cookies from "js-cookie";
import ButtonDropdown from "@/components/console/buttons/ButtonDropdown";
import helper from "@/helper";
import swal from "@/helper/swal";
import { useStore } from "vuex";
import {
  planDiscount,
  planPrice,
  planSalesPrice,
  planSoldOut,
  planListPrice,
} from "@/helper/plan";
import DashBoardArrowIcon from "@/components/console/icons/DashBoardArrowIcon.vue";

export default {
  name: "ClubPlanItem",
  components: { DashBoardArrowIcon, ButtonDropdown },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    clubResourceId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const state = reactive({
      planStatus: computed(() => {
        if (!props.plan.isPublic) {
          return "숨김";
        }

        if (state.soldOut) {
          return "품절";
        }

        if (moment(props.plan.startAt) > moment()) {
          return "판매 예정";
        }
        if (
          moment(props.plan.startAt) < moment() &&
          moment(props.plan.finishAt) > moment()
        ) {
          return "판매 중";
        }

        return "판매 종료";
      }),
      salesPrice: computed(() => {
        return planSalesPrice(props.plan, "front");
      }),
      currency: Cookies.get("locale") === "ko" ? "₩" : "$",
      priceObject: computed(() => {
        if (props.plan.prices?.KRW) {
          return props.plan.prices.KRW;
        }
        if (props.plan.prices?.USD) {
          return props.plan.prices.USD;
        }
        return {
          currency: "KRW",
          listPrice: 0,
          listPriceGoods: 0,
          listPriceTicket: 0,
          price: 0,
          priceGoods: 0,
          priceTicket: 0,
        };
      }),
      limitCount: computed(() => {
        return props.plan.limitCount
          ? `${helper.priceFormat(props.plan.limitCount)}명 한정`
          : "제한 없음";
      }),
      remainTickets: computed(() => {
        if (props.plan.limitCount > 0) {
          return `${helper.priceFormat(
            props.plan.limitCount - props.plan.paymentOrderCount
          )} 개`;
        } else {
          return "제한 없음";
        }
      }),
      showPriceInfo: computed(() => {
        return (
          !!state.priceObject.listPrice &&
          state.priceObject.listPrice > state.priceObject.price
        );
      }),
      listPrice: computed(() => {
        return planListPrice(props.plan);
      }),
      price: computed(() => {
        return planPrice(props.plan, "front");
      }),
      soldOut: computed(() => {
        return planSoldOut(props.plan);
      }),
      discount: computed(() => {
        return planDiscount(props.plan);
      }),
    });

    const statusLabel = computed(() => {
      let status = props.plan.status;
      switch (status) {
        case 4:
          return "판매 종료";
        case 8:
          return "숨김";
        default:
          return "판매중";
      }
    });

    const actions = {
      updatePlanStatus: (status) => {
        store
          .dispatch("newClubs/patchPlan", {
            clubResourceId: props.clubResourceId,
            planResourceId: props.plan.resourceId,
            data: {
              status: status,
            },
          })
          .then(() => {
            swal.successToast("상태가 변경되었습니다.");
          });
      },
    };

    return { state, actions, helper, statusLabel };
  },
};
</script>

<style src="./style.css" scoped></style>
